@import '../partials';

.flickity-page-dots {
  @include bp(lg) {
    display: flex !important;
  }
}

.info-image-credit {
  pointer-events: all;
  position: absolute;
  bottom: rem(24);
  right: rem(24);
  z-index: 3;

  &:hover {
    .info-image-credit__toggle {
      opacity: 1;
    }
  }

  &__toggle {
    cursor: pointer;
    opacity: 0.5;
    transition-property: opacity;
    transition-duration: $fast-duration;
    width: rem(24);
    height: rem(24);
    background-image: url('../DocumentDefault/Assets/info-icon.svg');
  }

  &__text {
    display: none;
    box-shadow: $drop-shadow;
    width: rem(360);
    background-color: $white;
    padding: rem(30) rem(20);
    position: absolute;
    top: rem(-24);
    transform: translateY(-100%);
    right: -100%;
    pointer-events: none;
    font-family: $sans-serif-body-font;
    line-height: 2.2;
    font-size: rem(10);

    &::after {
      content: " ";
      display: block;
      position: absolute;
      right: 0;
      bottom: rem(-16);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 rem(16) rem(16) 0;
      border-color: transparent $white transparent transparent;
    }

    &-title {
      text-transform: uppercase;
      font-size: rem(20);
      line-height: 0.8;
      font-family: $sans-serif-font;
      font-weight: $font-weight-bold;
      color: $gunmetal;
      margin-bottom: rem(12);
    }
  }
}
